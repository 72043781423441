<template>
    <div class="my_modal" align="center" style="z-index:3000 !important">
        <div class="my_modal-dialog size-md ZAfont_family ZAfont_size" style="align-items:center">
            <div class="my_modal-content">
                <div class="my_modal-header">
                    <div style="display:flex; align-items:center">
                        <div class="my_fontSize_1Point2 my_color_00338e" style="align-self:center">{{$t('3340')}}</div>
                    </div>
                    <div>
                        <span @click="change_valueDivergenceModal_Shown(false)" class="fal fa-times fa-2x"></span>
                    </div>
                </div>
                <div class="my_modal-body" style="height:calc(100vh - 200px)">
                    <div v-for="valueDivergence in statusHandling.status.valueDivergences">
                        <div style="margin-bottom:30px">
                            <div class="my_fontSize_1Point1 my_color_00338e">{{$t(valueDivergence.textKey)}}</div>
                            <table style="width:100%">
                                <tr style="border-bottom:1px solid grey">
                                    <th style="width:30%;text-align:right; padding:0rem 0.5rem">{{$t('3341')}}</th>
                                    <th style="width: 35%; text-align: right; border-left: 1px solid grey; border-right: 1px solid grey; padding: 0rem 0.5rem">{{$t('1282')}}</th>
                                    <th style="width: 35%; text-align: left; padding: 0rem 0.5rem">{{$t('3342')}}</th>
                                </tr>
                                <tr v-for="divergence in valueDivergence.divergences" style="border-bottom:1px solid grey">
                                    <td style="width: 30%; text-align: right; padding: 0rem 0.5rem">
                                        <span>{{$t(divergence.textKey)}} </span>
                                        <span>[{{divergence.unit}}]</span>
                                    </td>
                                    <td style="width: 35%; text-align: right; border-left: 1px solid grey; border-right: 1px solid grey; padding: 0rem 0.5rem">{{divergence.before}}</td>
                                    <td style="width: 35%; text-align: left; padding: 0rem 0.5rem">{{divergence.after}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="my_modal-footer" style="border-top:none; flex-direction:column">
                    <div v-for="statusModalClosingOption in statusHandling.status.statusModalClosingOptions" style="margin-top:10px; width:100%">
                        <button @click="hide_statusModal(statusModalClosingOption.closingOption)" class="btn my_btn_standard" style="width:100%">{{$t(statusModalClosingOption.displayedDesignation.textKey)}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ValueDivergenceModal',
        props: {
            statusHandling: Object,
        },

        methods: {
            hide_statusModal(closingOption) {
                this.$root.$emit('hide_statusModal', closingOption)
            },

            change_valueDivergenceModal_Shown(value) {
                this.$store.dispatch('statusHandling/change_valueDivergenceModal_Shown_storeAction', value);
            },
        },
    }

</script>