<template>
    <div :class="['c-app', routename]">
        <ValueDivergenceModal v-bind:statusHandling="statusHandling" v-bind:class="{show: valueDivergenceModal_Shown}"></ValueDivergenceModal>
        <StatusModal v-bind:statusHandling="statusHandling" v-bind:class="{show: statusModal_Shown}"></StatusModal>
        <LoaderModal v-bind:reasonWhy_loaderModal_Shown="reasonWhy_loaderModal_Shown" v-bind:class="{show: loaderModal_Shown}"></LoaderModal>
        <UserPreferences v-bind:class="{show: userPreference_Modal_Shown}"></UserPreferences>
        <div v-if="valueDivergenceModal_Shown">TESTEST</div>
        <div class="c-wrapper">
            <TheHeader />
            <div class="c-body" style="position:relative">
                <main class="c-main">
                    <div class="container-fluid">
                        <router-view></router-view>
                    </div>
                </main>
            </div>
            <TheFooter />
        </div>
    </div>
</template>

<script>
    import router from '../router';
    import TheHeader from './TheHeader';
    import TheFooter from './TheFooter';
    import StatusModal from '../views/statusHandling/StatusModal';
    import ValueDivergenceModal from '../views/statusHandling/ValueDivergenceModal';
    import UserPreferences from '../views/UserPreferences';
    import LoaderModal from '../views/loader/LoaderModal';

    export default {
        name: 'TheContainer',
        components: {
            TheHeader,
            TheFooter,
            StatusModal,
            ValueDivergenceModal,
            LoaderModal,
            UserPreferences,
        },

        computed: {
            routename() {
                return this.$route.name;
            },

            statusModal_Shown: {
                get() {
                    return this.$store.state.statusHandling.statusModal_Shown;
                },
            },
            valueDivergenceModal_Shown: {
                get() {
                    return this.$store.state.statusHandling.valueDivergenceModal_Shown;
                },
            },
            statusHandling: {
                get() {
                    return { data: this.$store.state.statusHandling.data, status: this.$store.state.statusHandling.status };
                }
            },
            accessToken_Expired: {
                get() {
                    return this.$store.state.statusHandling.accessToken_Expired;
                }
            },

            loaderModal_Shown: {
                get() {
                    return this.$store.state.container.loaderModal_Shown
                },
            },
            reasonWhy_loaderModal_Shown: {
                get() {
                    return this.$store.state.container.reasonWhy_loaderModal_Shown;
                },
            },

            userPreference_Modal_Shown: {
                get() {
                    return this.$store.state.user.userPreference_Modal_Shown;
                }
            },

            configurationOverviewsId: {
                get() {
                    return this.$store.state.configurations.configurationOverviewsId;
                },
            },
        },
        mounted() {
            this.$root.$on('hide_statusModal', async (closingOption) => {
                var data = this.$store.state.statusHandling.data;
                var status = this.$store.state.statusHandling.status;

                switch (closingOption) {
                    case this.$closingOptions.close:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction')
                        break;
                    case this.$closingOptions.retry:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction')
                        switch (status.statusInContextOf) {
                            case 'updateAndOrDelete_databaseRecords':
                                this.$store.dispatch('configurations/selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction', data);
                                break;
                            case 'get_elevatorSystem_and_construction_and_suspensionMeans_and_elevatorMotor_TractionSheave_ValueListsAndSelections_Informations':
                                this.$store.dispatch('inputs/get_elevatorSystem_and_construction_and_suspensionMeans_and_elevatorMotor_TractionSheave_ValueListsAndSelections_Informations_storeAction')
                                break;
                            case 'check_inputs_byCalculationDll_storeAction':
                                this.$store.dispatch('inputs/check_inputs_byCalculationDll_storeAction')
                                break;
                            case 'insertOrUpdate_databaseRecords_at_elevatorSystems_and_constructions_and_suspensionMeans_and_elevatorMotor_TractionSheaves':
                                this.$store.dispatch('inputs/check_inputs_byCalculationDll_storeAction')
                                break;
                            default:
                                this.$store.dispatch('statusHandling/change_dataAndStatus_storeAction', this.$status_for_statusCode_7600000);
                                break;
                        }
                        break;
                    case this.$closingOptions.continue:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction')
                        switch (status.statusInContextOf) {
                            case 'check_configuration_fromDatabase':
                                this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
                                this.$store.dispatch('configurations/set_configurationOverviewsId_at_sessionStorage_storeAction', data.configurationOverviewsId);
                                this.$store.dispatch('configurations/change_configuration_Modifiable_storeAction', data.configuration_Modifiable);
                                await this.$store.dispatch('configurations/determine_configurationSteps_storeAction')
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'determine_configurationSteps') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    await this.$store.dispatch('configurations/select_databaseRecords_storeAction', { selectDatabaseRecordsFor: data.routeSlug });
                                    if ((this.$store.state.statusHandling.status.statusInContextOf == 'select_databaseRecords') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                        switch (data.routeSlug) {
                                            case 'inputs':
                                                this.$store.dispatch('configurations/check_toDos_at_inputs_storeAction')
                                                break;
                                            case 'driveTechnologies':
                                                this.$store.dispatch('configurations/check_toDos_at_driveTechnologies_storeAction')
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                }
                                break;
                            case 'check_inputs_byCalculationDll':
                                this.$store.dispatch('inputs/insert_databaseRecords_at_elevatorSystems_and_constructions_and_suspensionMeans_and_elevatorMotor_TractionSheaves_storeAction', data)
                                break;
                            case 'update_databaseRecord_at_configurationOverviews_bySetting_Note':
                                router.push('../configurationStep/driveTechnologies');
                                break;
                            case 'elevatorMotor_Machine':
                                const firstIndex_whichMeetConditions = this.$store.state.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => ((i.elevatorMotor_Machine.blockNumberMachine == data.valueDivergence.divergences[0].before.elevatorMotor_Machine.blockNumberMachine) && (i.elevatorMotor_Machine.blockNumberGear == data.valueDivergence.divergences[0].before.elevatorMotor_Machine.blockNumberGear) && (i.elevatorMotor_TractionSheave.blockNumber == data.valueDivergence.divergences[0].before.elevatorMotor_TractionSheave.blockNumber)));
                                if (firstIndex_whichMeetConditions > -1) {
                                    this.$store.dispatch('driveTechnologies/change_Selected_atElementOf_elevatorMotor_Machine_Selection_storeAction', firstIndex_whichMeetConditions)
                                }
                                else {
                                    // error
                                }
                                break;
                            case 'get_elevatorMotor_Machine_Informations':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_get_elevatorMotor_Machine_Informations_storeAction', data)
                                break;
                            case 'get_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Informations':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_get_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Informations_storeAction', data)
                                break;
                            case 'get_wrappingAngleCalculation_Informations':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_get_wrappingAngleCalculation_Informations_storeAction', data)
                                break;
                            case 'check_wrappingAngleCalculation_Data_byCalculationDll':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_check_wrappingAngleCalculation_Data_byCalculationDll_storeAction')
                                break;
                            case 'get_tractionCheck_Informations':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_get_tractionCheck_Informations_storeAction', data)
                                break;
                            case 'checkIf_tractionCheck_Passed_storeAction':
                                this.$store.dispatch('driveTechnologies/checkIf_tractionCheck_Inputs_ValueListsAndSelectionsAndPermissibleValueRanges_Loaded2_storeAction');
                                break;
                            case 'get_elevatorMotor_Machine_TractionSheaveSide_Informations':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_get_elevatorMotor_Machine_TractionSheaveSide_Informations_storeAction', data)
                                break;
                            case 'get_elevatorMotor_Machine_InstallationPosition_Informations':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_get_elevatorMotor_Machine_InstallationPosition_Informations_storeAction', data)
                                break;
                            case 'get_elevatorMotor_TemperatureMonitoring_Informations':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_get_elevatorMotor_TemperatureMonitoring_Informations_storeAction', data)
                                break;
                            case 'get_elevatorMotor_ForcedVentilation_Informations':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_get_elevatorMotor_ForcedVentilation_Informations_storeAction', data)
                                break;
                            case 'get_elevatorMotor_Brake_Informations':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_get_elevatorMotor_Brake_Informations_storeAction', data)
                                break;
                            case 'get_elevatorMotor_Encoder_Informations':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_get_elevatorMotor_Encoder_Informations_storeAction', data)
                                break;
                            case 'get_frame_Informations':
                                this.$store.dispatch('driveTechnologies/toDos_afterResponseOf_get_frame_Informations_storeAction', data)
                                break;
                            case 'get_inverter_Informations':
                                this.$store.dispatch('controlTechnologies/toDos_afterResponseOf_get_inverter_Informations_storeAction', data)
                                if (this.$store.state.configurations.configurationSteps.inverter.storedInDatabase == true) {
                                    await this.$store.dispatch('controlTechnologies/change_Selected_atElementOf_inverter_Selection_basedOn_databaseRecord_storeAction')
                                }
                                this.$store.dispatch('configurations/check_toDos_at_controlTechnologies_storeAction')
                                break;
                            case 'get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations':
                                this.$store.dispatch('controlTechnologies/toDos_afterResponseOf_get_unintendedCarMovementCheck_Inputs_ValueListAndSelectionsAndPermissibleValueRanges_Informations_storeAction', data)
                                break;
                            case 'get_unintendedCarMovementCheck_Informations':
                                this.$store.dispatch('controlTechnologies/toDos_afterResponseOf_get_unintendedCarMovementCheck_Informations_storeAction', data)
                                break;
                            case 'check_unintendedCarMovementCheck_Valid':
                                this.$store.dispatch('controlTechnologies/toDos_afterResponseOf_check_unintendedCarMovementCheck_Valid_storeAction', data)
                                break;
                            case 'get_brakeResistor_Informations':
                                this.$store.dispatch('controlTechnologies/toDos_afterResponseOf_get_brakeResistor_Informations_storeAction', data)
                                break;
                            case 'get_powerRecuperationUnit_Informations':
                                this.$store.dispatch('controlTechnologies/toDos_afterResponseOf_get_powerRecuperationUnit_Informations_storeAction', data)
                                break;
                            case 'get_brakeControl_Informations':
                                this.$store.dispatch('controlTechnologies/toDos_afterResponseOf_get_brakeControl_Informations_storeAction', data)
                                break;
                            case 'get_energyEfficiencyRating_PermissibleValueRanges_Informations':
                                this.$store.dispatch('controlTechnologies/toDos_afterResponseOf_get_energyEfficiencyRating_PermissibleValueRanges_Informations_storeAction', data)
                                break;
                            case 'get_energyEfficiencyRating_Informations':
                                this.$store.dispatch('controlTechnologies/toDos_afterResponseOf_get_energyEfficiencyRating_Informations_storeAction', data)
                                break;
                            case 'get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations':
                                this.$store.dispatch('controlTechnologies/toDos_afterResponseOf_get_comparisonOfPossibilitiesToConvertRegenerativeEnergy_Informations_storeAction', data)
                                break;
                            case 'check_configuration_byCalculationDll':
                                this.$store.dispatch('summary/toDos_afterResponseOf_check_configuration_byCalculationDll_storeAction')
                                break;
                            case 'get_htmlFileContent':
                                this.$store.dispatch('summary/get_htmlFileContent_storeAction', false)
                                break;
                            default:
                                break;
                        }
                        break;
                    case this.$closingOptions.to_inputs:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        this.$store.dispatch('inputs/change_inputs_toDefault_storeAction');
                        this.$store.dispatch('driveTechnologies/change_driveTechnologies_toDefault_storeAction');
                        this.$store.dispatch('controlTechnologies/change_controlTechnologies_toDefault_storeAction');
                        this.$store.dispatch('frames/change_frames_toDefault_storeAction');
                        this.$store.dispatch('summary/change_summary_toDefault_storeAction');
                        this.$store.dispatch('configurations/change_configurations_toDefault_storeAction');
                        router.push('../configurationStep/inputs');
                        break;
                    case this.$closingOptions.to_elevatorMotor_Machine:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        switch (status.statusInContextOf) {
                            case 'check_configuration_fromDatabase':
                                await this.$store.dispatch('configurations/toDos_after_configuration_fromDatabase_Checked_storeAction', data)
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'select_databaseRecords') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/driveTechnologies');
                                }
                                break;
                            case 'check_configuration_fromFile':
                                this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
                                await this.$store.dispatch('configurations/insert_databaseRecords_after_configuration_Checked_storeAction', data);
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'insert_databaseRecords_after_configuration_Checked') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/driveTechnologies');
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case this.$closingOptions.to_elevatorMotor_TemperatureMonitoring:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        switch (status.statusInContextOf) {
                            case 'check_configuration_fromDatabase':
                                await this.$store.dispatch('configurations/toDos_after_configuration_fromDatabase_Checked_storeAction', data)
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'select_databaseRecords') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/driveTechnologies');
                                }
                                break;
                            case 'check_configuration_fromFile':
                                this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
                                await this.$store.dispatch('configurations/insert_databaseRecords_after_configuration_Checked_storeAction', data);
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'insert_databaseRecords_after_configuration_Checked') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/driveTechnologies');
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case this.$closingOptions.to_elevatorMotor_ForcedVentilation:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        switch (status.statusInContextOf) {
                            case 'check_configuration_fromDatabase':
                                await this.$store.dispatch('configurations/toDos_after_configuration_fromDatabase_Checked_storeAction', data)
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'select_databaseRecords') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/driveTechnologies');
                                }
                                break;
                            case 'check_configuration_fromFile':
                                this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
                                await this.$store.dispatch('configurations/insert_databaseRecords_after_configuration_Checked_storeAction', data);
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'insert_databaseRecords_after_configuration_Checked') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/driveTechnologies');
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case this.$closingOptions.to_elevatorMotor_Brake:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        switch (status.statusInContextOf) {
                            case 'check_configuration_fromDatabase':
                                await this.$store.dispatch('configurations/toDos_after_configuration_fromDatabase_Checked_storeAction', data)
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'select_databaseRecords') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/driveTechnologies');
                                }
                                break;
                            case 'check_configuration_fromFile':
                                this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
                                await this.$store.dispatch('configurations/insert_databaseRecords_after_configuration_Checked_storeAction', data);
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'insert_databaseRecords_after_configuration_Checked') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/driveTechnologies');
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case this.$closingOptions.to_elevatorMotor_Encoder:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        switch (status.statusInContextOf) {
                            case 'check_configuration_fromDatabase':
                                await this.$store.dispatch('configurations/toDos_after_configuration_fromDatabase_Checked_storeAction', data)
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'select_databaseRecords') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/driveTechnologies');
                                }
                                break;
                            case 'check_configuration_fromFile':
                                this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
                                await this.$store.dispatch('configurations/insert_databaseRecords_after_configuration_Checked_storeAction', data);
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'insert_databaseRecords_after_configuration_Checked') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/driveTechnologies');
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case this.$closingOptions.to_inverter:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        switch (status.statusInContextOf) {
                            case 'check_configuration_fromDatabase':
                                await this.$store.dispatch('configurations/toDos_after_configuration_fromDatabase_Checked_storeAction', data)
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'select_databaseRecords') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/controlTechnologies');
                                }
                                break;
                            case 'check_configuration_fromFile':
                                this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
                                await this.$store.dispatch('configurations/insert_databaseRecords_after_configuration_Checked_storeAction', data);
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'insert_databaseRecords_after_configuration_Checked') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/controlTechnologies');
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case this.$closingOptions.to_conversionOfRegenerativeEnergy:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        switch (status.statusInContextOf) {
                            case 'check_configuration_fromDatabase':
                                await this.$store.dispatch('configurations/toDos_after_configuration_fromDatabase_Checked_storeAction', data)
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'select_databaseRecords') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/controlTechnologies');
                                }
                                break;
                            case 'check_configuration_fromFile':
                                this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
                                await this.$store.dispatch('configurations/insert_databaseRecords_after_configuration_Checked_storeAction', data);
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'insert_databaseRecords_after_configuration_Checked') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/controlTechnologies');
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case this.$closingOptions.to_brakeControl:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        switch (status.statusInContextOf) {
                            case 'check_configuration_fromDatabase':
                                await this.$store.dispatch('configurations/toDos_after_configuration_fromDatabase_Checked_storeAction', data)
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'select_databaseRecords') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/controlTechnologies');
                                }
                                break;
                            case 'check_configuration_fromFile':
                                this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
                                await this.$store.dispatch('configurations/insert_databaseRecords_after_configuration_Checked_storeAction', data);
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'insert_databaseRecords_after_configuration_Checked') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/controlTechnologies');
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case this.$closingOptions.to_summary:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        switch (status.statusInContextOf) {
                            case 'check_configuration_fromDatabase':
                                var databaseRecords_final = false

                                if (status.valueDivergences != null) {
                                    this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
                                    await this.$store.dispatch('configurations/update_databaseRecords_after_configuration_Checked_storeAction', data);
                                    if ((this.$store.state.statusHandling.status.statusInContextOf == 'update_databaseRecords_after_configuration_Checked') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                        databaseRecords_final = true;
                                    }
                                }
                                else {
                                    databaseRecords_final = true;
                                }

                                if (databaseRecords_final == true) {
                                    this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
                                    this.$store.dispatch('configurations/set_configurationOverviewsId_at_sessionStorage_storeAction', data.configurationOverviewsId);
                                    this.$store.dispatch('configurations/change_configuration_Modifiable_storeAction', data.configuration_Modifiable);
                                    await this.$store.dispatch('configurations/determine_configurationSteps_storeAction')
                                    if ((this.$store.state.statusHandling.status.statusInContextOf == 'determine_configurationSteps') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                        router.push('../configurationStep/summary');
                                    }
                                }
                                this.$store.dispatch('configurations/todos_configurationNotValid_storeAction');
                                break;
                            case 'check_configuration_fromFile':
                                this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
                                await this.$store.dispatch('configurations/insert_databaseRecords_after_configuration_Checked_storeAction', data);
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'insert_databaseRecords_after_configuration_Checked') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/summary');
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case this.$closingOptions.to_input_summaryOK:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        switch (status.statusInContextOf) {
                            case 'check_configuration_fromDatabase':
                                await this.$store.dispatch('configurations/toDos_after_configuration_fromDatabase_Checked_storeAction', data)
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'select_databaseRecords') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/inputs');
                                }
                                break;
                            case 'check_configuration_fromFile':
                                this.$store.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
                                await this.$store.dispatch('configurations/insert_databaseRecords_after_configuration_Checked_storeAction', data);
                                if ((this.$store.state.statusHandling.status.statusInContextOf == 'insert_databaseRecords_after_configuration_Checked') && (this.$store.state.statusHandling.status.statusCode.startsWith('2'))) {
                                    router.push('../configurationStep/inputs');
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case this.$closingOptions.back_to_input:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction');
                        router.push('../configurationStep/inputs');
                        break;
                    case this.$closingOptions.to_startPage:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction')
                        router.push('/');
                        break;
                    case this.$closingOptions.logout:
                        this.$store.dispatch('statusHandling/change_statusHandling_toDefault_storeAction')
                        this.$store.dispatch('account/logout');
                        break;
                    default:
                        this.$store.dispatch('statusHandling/change_dataAndStatus_storeAction', this.$status_for_statusCode_7600000);
                        break;
                }
            });
        },
    }
</script>

<style>
    body {
        color: #333;
    }

    .btn {
        border-radius: 0px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }

    .card-body {
        padding: 0px !important;
    }

    .c-body {
        background-color: #ffffff;
    }

    .c-main {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        min-width: 0;
        padding-top: 0rem;
    }

    .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        margin-right: auto;
        margin-left: auto;
    }

    .c-switch-slider {
        border: 1px solid #646363;
    }

        .c-switch-slider::before {
            border: 1px solid #646363;
        }

    .custom-select {
        border: 1px solid #646363;
        border-radius: 0px;
    }

    .form-control {
        border: 1px solid #646363;
        border-radius: 0px;
        color: #000000;
        max-width: 100%;
    }

        .form-control:focus {
            color: #000000;
            background-color: #fff;
            border-color: #646363;
            outline: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .form-control:disabled {
            background-color: transparent;
        }

    textarea:focus {
        outline: none !important;
        border-color: Highlight !important;
        box-shadow: 0 0 5px Highlight !important;
        background-color: rgba(65,105,225,0.3) !important;
    }

    select:focus {
        outline: none !important;
        border-color: Highlight !important;
        box-shadow: 0 0 5px Highlight !important;
        background-color: rgba(65,105,225,0.3) !important;
    }

    input:focus {
        outline: none !important;
        border-color: Highlight !important;
        box-shadow: 0 0 5px Highlight !important;
        background-color: rgba(65,105,225,0.3) !important;
    }

    input:disabled {
        background-color: rgba(169,169,169,0.3) !important;
    }

    .tooltip-text {
        visibility: hidden;
        position: absolute;
        z-index: 2;
        color: white;
        font-size: 1rem;
        background-color: #00338e;
        border-radius: 10px;
        padding: 10px 15px 10px 15px;
    }

    .tooltip-errorText {
        visibility: hidden;
        position: absolute;
        z-index: 2;
        color: white;
        font-size: 1rem;
        background-color: #f82828;
        border-radius: 10px;
        padding: 10px 15px 10px 15px;
    }

    .hover-text:hover .tooltip-text {
        visibility: visible;
    }

    .hover-errorText:hover .tooltip-errorText {
        visibility: visible;
    }

    #tooltip_top {
        top: -40px;
        left: -50%;
    }

    #tooltip_bottom {
        top: 25px;
        left: -50%;
    }

    #tooltip_left {
        top: -8px;
        right: 120%;
    }

    #tooltip_right {
        top: -8px;
        left: 120%;
    }

    .hover-text {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: #00338e;
        border-radius: 50px;
        display: inline-block;
        color: white;
        font-weight: bold;
        font-size: 12px;
        vertical-align: middle;
        text-align: center;
    }

    .hover-errorText {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: #f82828;
        border-radius: 50px;
        display: inline-block;
        color: white;
        font-weight: bold;
        font-size: 12px;
        vertical-align: middle;
        text-align: center;
    }


    .has-tooltipdetails {
        margin-left: 10px;
        display: inline;
        font-size: 1rem;
    }

    .tooltipdetails-wrapper {
        position: absolute;
        visibility: hidden;
    }

    .has-tooltipdetails:hover .tooltipdetails-wrapper {
        visibility: visible;
        opacity: 0.9;
    }

    .tooltipdetails {
        display: block;
        position: relative;
        width: auto;
        height: auto;
        color: #ffffff;
        background: #00338e;
        text-align: center;
        border-radius: 8px;
        font-size: 1rem;
    }

        .tooltipdetails:after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 100%;
            margin-left: -8px;
            width: 0;
            height: 0;
        }

    textarea {
        /*background-color: rgba(0,51,142,0.1) !important;*/
        /*background-color: rgba(255,255,0,0.2) !important;*/
        /*background-color: rgba(0,0,255,0.07) !important;*/
        background-color: rgba(65,105,225,0.1) !important;
    }

    select {
        /*background-color: rgba(0,51,142,0.1) !important;*/
        /*background-color: rgba(255,255,0,0.2) !important;*/
        /*background-color: rgba(0,0,255,0.07) !important;*/
        background-color: rgba(65,105,225,0.1);
    }

        select option.disabled:disabled {
            background-color: rgba(120,120,120,0.2) !important;
        }

    input {
        font-size: 16px !important;
        /*background-color: rgba(0,51,142,0.1) !important;*/
        /*background-color: rgba(255,255,0,0.2) !important;*/
        /*background-color: rgba(0,0,255,0.07) !important;*/
        background-color: rgba(65,105,225,0.1) !important;
    }

        input[type=checkbox] {
            /*transform: scale(1.5);*/
            width: 18px;
            height: 18px;
        }

    .input-group-text {
        border-radius: 0px;
        /*background-color: #e7e7e7;*/
        background-color: #b1b1b1;
        color: #000000;
        font-size: 16px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
    }

        .input-group-text:hover {
            overflow: visible;
        }

    option {
        font-size: 16px;
    }

    .table tbody {
        border: 1px solid black;
    }

        .table tbody + tbody {
            border-top: 1px solid black;
        }

    .table td {
        border-top: none;
    }

    .my_background-color_00338e {
        background-color: #00338e;
    }

    .my_background-color_aac3e1 {
        background-color: #aac3e1;
    }

    .my_background-color_6491c8 {
        background-color: #6491c8;
    }

    .my_background-color_e7e7e7 {
        background-color: #e7e7e7;
    }

    .my_background-color_cfab28 {
        background-color: #cfab28;
    }

    .my_btn_icon-check {
        height: 40px;
        width: 40px;
        background-color: transparent;
        padding: 0rem;
    }

    .my_btn_changeSelection {
        background-color: transparent;
        border-color: #00338e;
        border-radius: 0px;
        color: #00338e;
        font-family: Arial, sans-serif;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 5px;
        margin-bottom: 5px;
    }

        .my_btn_changeSelection:hover {
            background-color: #0f2e68;
            border-color: #0f2e68;
            color: #ffffff;
        }

        .my_btn_changeSelection:active {
            background-color: #0f2e68 !important;
            border-color: #0f2e68 !important;
            color: #ffffff !important;
        }

        .my_btn_changeSelection:focus {
            background-color: #0f2e68 !important;
            border-color: #0f2e68 !important;
            box-shadow: none !important;
            color: #ffffff !important;
        }

    .my_btn_nextConfigurationStep {
        background-color: transparent;
        border-color: #00338e;
        border-radius: 0px;
        color: #00338e;
        font-family: Arial, sans-serif;
        font-size: 1rem;
        font-weight: 600;
        padding: 15px 30px;
    }

        .my_btn_nextConfigurationStep:hover {
            background-color: #0f2e68;
            border-color: #0f2e68;
            color: #ffffff;
        }

        .my_btn_nextConfigurationStep:active {
            background-color: #0f2e68 !important;
            border-color: #0f2e68 !important;
            color: #ffffff !important;
        }

        .my_btn_nextConfigurationStep:focus {
            background-color: #0f2e68 !important;
            border-color: #0f2e68 !important;
            box-shadow: none !important;
            color: #ffffff !important;
        }

    .my_btn_nextConfigurationStep_position {
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: right;
    }

    .my_btn_startConfiguration {
        background-color: #cfab28;
        border-color: #cfab28;
        border-radius: 0px;
        color: #ffffff;
        font-family: Arial, sans-serif;
        font-size: 1rem;
        font-weight: 600;
        padding: 15px 30px;
    }

    .my_btn_standard {
        background-color: transparent;
        border-color: #00338e;
        border-radius: 0px;
        color: #00338e;
        font-family: Arial, sans-serif;
        font-size: 1rem;
        font-weight: 600;
        /*        padding: 8px 25px;*/
    }

        .my_btn_standard:hover {
            background-color: #0f2e68;
            border-color: #0f2e68;
            color: #ffffff;
        }

        .my_btn_standard:active {
            background-color: #0f2e68 !important;
            border-color: #0f2e68 !important;
            color: #ffffff !important;
        }

        .my_btn_standard:focus {
            background-color: #0f2e68 !important;
            border-color: #0f2e68 !important;
            box-shadow: none !important;
            color: #ffffff !important;
        }

    .my_btn_standard_blue {
        background-color: #00338e;
        border-color: #00338e;
        border-radius: 0px;
        color: #ffffff;
        font-family: Arial, sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }


        .my_btn_standard_blue:disabled {
            background-color: grey;
            border-color: grey;
            color: #000000;
        }

        .my_btn_standard_blue:hover:not([disabled]) {
            background-color: #0f2e68;
            border-color: #0f2e68;
            color: #ffffff;
        }

        .my_btn_standard_blue:active:not([disabled]) {
            background-color: #0f2e68 !important;
            border-color: #0f2e68 !important;
            color: #ffffff !important;
        }

        .my_btn_standard_blue:focus:not([disabled]) {
            background-color: #0f2e68 !important;
            border-color: #0f2e68 !important;
            box-shadow: none !important;
            color: #ffffff !important;
        }

    .my_btn_details {
        background-color: #ffffff;
        border-color: #ffffff;
        border-radius: 0px;
        padding-left: 0px;
        margin-left: 0px;
    }

        .my_btn_details:active {
            outline: 0 !important;
            border-color: #ffffff !important;
            border:none !important;
        }
        .my_btn_details:focus {
            outline: 0 !important;
            border-color: #ffffff !important;
            border:none !important;
        }
        .my_btn_details:hover {
            outline: 0 !important;
            border-color: #ffffff !important;
            border: none !important;
        }

    .my_card_brake {
        height: 100px;
    }

    .my_card_brake_text {
        text-align: right;
    }

    .my_check {
        border: 1px solid #ced2d8;
        width: 35px;
        height: 35px;
    }

        .my_check .active {
            background-color: #1c69d4;
            height: 100%;
        }

        .my_check .icon {
            color: #ffffff;
            font-size: 25px;
            text-align: center;
            margin-top: 4px;
            margin-left: 4px;
        }

    .my_color_ff0000 {
        color: #ff0000;
    }

    .my_color_000000 {
        color: #000000;
    }

    .my_color_ffffff {
        color: #ffffff;
    }

    .my_color_00338e {
        color: #00338e;
    }

    .my_color_87888a {
        color: #87888a;
    }

    .my_color_c7066e {
        color: #c7066e;
    }

    .my_color_cfab28 {
        color: #cfab28;
    }

    .my_color_6491c8 {
        color: #6491c8;
    }

    .my_elevatorMotor_Machine {
        list-style: none;
        margin: 0;
        padding: 0
    }

        .my_elevatorMotor_Machine li {
            display: block;
            /*border: 1px solid #ced2d8;*/
            margin-bottom: 15px
        }

        .my_elevatorMotor_Machine .selected {
            border: 2px solid grey;
        }

    .my_fontSize_1 {
        font-size: 1rem;
    }

    .my_fontSize_1Point1 {
        font-size: 1.1rem;
    }

    .my_fontSize_1Point2 {
        font-size: 1.2rem;
    }

    .my_fontSize_1Point3 {
        font-size: 1.3rem;
    }

    .my_fontSize_1Point4 {
        font-size: 1.4rem;
    }

    .my_fontSize_1Point5 {
        font-size: 1.5rem;
    }

    .my_fontSize_1Point9 {
        font-size: 1.9rem;
    }

    .my_fontWeight_400 {
        font-weight: 400;
    }

    .my_fontWeight_500 {
        font-weight: 500;
    }

    .my_fontWeight_600 {
        font-weight: 600;
    }

    .my_font-weight_500 {
        font-weight: 500;
    }

    .my_font-weight_600 {
        font-weight: 600;
    }

    .my_font-weight_700 {
        font-weight: 700;
    }

    .my_font-weight_800 {
        font-weight: 800;
    }

    .my_filter {
        border: 2px solid #ced2d8;
        padding: 15px;
    }

    .my_grid_filter {
        display: grid;
        grid-template-columns: 325px auto;
        grid-column-gap: 50px;
    }

    .my_heading_ucm_1 {
        color: #00338e;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.012em;
    }

    .my_heading_ucm_2 {
        color: #000000;
        font-size: 16px;
        font-weight: 600;
    }

    .my_icon-check {
        color: #00338e;
        font-size: 25px;
    }

    .my_img_machinePositions {
        height: 150px;
    }


    .my_input {
        border: 1px solid #646363;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        width: 100%;
    }

        .my_input:focus {
            color: #000000;
            background-color: #fff;
            border-color: #646363;
            outline: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .my_input::placeholder {
            color: #000000;
        }

    .my_input_background_color_at_validInput {
        /*background-color: #ffffff !important;*/
    }

    .my_input_border_at_invalidInput {
        border: 2px solid red;
    }

        .my_input_border_at_invalidInput:focus {
            border: 2px solid red !important;
        }

    .my_input_combobox_button {
        border: 1px solid #646363;
        z-index: 1 !important;
        background-color: #ffffff;
        height: calc(1.5em + 0.75rem + 2px);
    }

    .my_input_combobox_input {
        z-index: 11;
        position: relative;
    }

        .my_input_combobox_input::placeholder {
            color: #000000;
        }

    .my_input_combobox_select {
        z-index: 10;
        position: absolute;
        opacity: 0.001;
        width: calc(100% - 60px);
    }

    .my_input_text_position {
        position: absolute;
        bottom: 0;
    }

    .my_input_unit {
        border: 1px solid #646363;
        height: calc(1.5em + 0.75rem + 2px);
        width: 65px !important;
        text-overflow: '...';
    }

    .my_input-group {
        display: flex;
        margin: 0;
        padding: 0;
        align-items: stretch
    }

    .my_input-group_unit {
        border-radius: 0px;
        /*background-color: #e7e7e7;*/
        background-color: #b1b1b1;
        flex: 0 0 100px;
        border-right: 1px solid #646363;
        border-top: 1px solid #646363;
        border-bottom: 1px solid #646363;
        display: flex;
        padding: 0.375rem 0.75rem;
        color: #000000;
        font-size: 16px;
    }

    .my_layout {
        margin: 24px 4% 24px 4%;
    }

    .my_layout_at_fixed_configurationStep_navigation {
        margin: 78px 4% 24px 4%;
    }

    .my_machinePosition_Selection ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
    }

    .my_machinePosition_Selection li {
        border-bottom: 1px solid #e3e3e3;
        padding: 5px 0px 5px 0px;
        text-align: center;
        width: 20%;
    }

    .my_machinePosition_Selection .active {
        border-bottom: 5px solid #6491c8;
        color: #00338e;
        font-weight: 600;
    }


    .my_unorderedList_at_construction ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
    }

    .my_unorderedList_at_construction li {
        border: 1px solid #ced2d8;
        width: calc(50% - 10px);
        text-align: center;
        padding: 0.75rem;
        align-items: stretch;
    }

        .my_unorderedList_at_construction li:first-child {
            margin-right: 10px;
        }

        .my_unorderedList_at_construction li:last-child {
            margin-left: 10px;
        }

    .my_unorderedList_at_construction .active {
        border: 1px solid blue;
    }

    .my_modal {
        display: none;
        position: fixed;
        z-index: 1001;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        background-color: rgba(0,51,142,0.8);
    }

        .my_modal.show {
            display: block;
        }

    .my_modal-dialog {
        display: flex;
        /*        align-items: flex-start;*/
        min-height: 100%;
        max-width: 100%;
    }

        .my_modal-dialog.size-sm {
            min-height: 100%;
            max-width: 550px;
        }

        .my_modal-dialog.size-md {
            min-height: 100%;
            max-width: 850px;
        }

        .my_modal-dialog.size-lg {
            min-height: 100%;
            max-width: 1275px;
        }

    .my_modal-content {
        background-color: #fefefe;
        position: relative;
        border: 1px solid #888;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: 20px;
    }

    .my_modal-header {
        border-bottom: 1px solid #888;
        /*        height: 60px;*/
        padding: 0.5rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .my_modal-body {
        overflow-y: auto;
        padding: 0.75rem;
        text-align: left;
    }

    .my_modal-footer {
        display: flex;
        border-top: 1px solid #888;
        /*        height: 60px;*/
        padding: 0.5rem;
        /*        justify-content: flex-end;*/
        align-items: flex-end;
    }

    .my_row {
        margin-top: 10px;
    }

    .my_select {
        border-radius: 0px;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
    }

    my_select:disabled {
        background-color: #ebebe4 !important;
    }

    .my_select:focus {
        outline: none;
    }

    .my_table_suspensionMeans {
        width: 100%;
    }

        .my_table_suspensionMeans thead {
            display: block;
            color: #ffffff;
            width: calc(100% - 0.9vw);
        }

            .my_table_suspensionMeans thead tr {
                background-color: #6491c8;
            }

            .my_table_suspensionMeans thead th, .my_table_suspensionMeans tbody td {
                padding: 0rem 0.5rem;
            }

        .my_table_suspensionMeans tbody {
            display: block;
            max-height: 100vh;
            overflow-y: auto;
            width: 100%;
            color: #000000;
        }

            .my_table_suspensionMeans tbody tr:nth-child(even) {
                background-color: #f2f2f2;
            }

            .my_table_suspensionMeans tbody tr:hover {
                background-color: #257AFD;
                color: #ffffff;
            }

    .my_table_configurationsOverview {
        width: 100%;
    }

        .my_table_configurationsOverview thead {
            display: block;
            color: #000000;
            width: calc(100% - 0.9vw);
        }

            .my_table_configurationsOverview thead th, .my_table_configurationsOverview tbody td {
                padding: 0.2rem 0.5rem;
            }

        .my_table_configurationsOverview tbody {
            display: block;
            max-height: 100vh;
            overflow-y: auto;
            width: 100%;
            overflow-x: hidden;
        }

            .my_table_configurationsOverview tbody tr:hover {
                background-color: #257AFD;
                color: #ffffff;
            }

            .my_table_configurationsOverview tbody tr:active {
                background-color: #257AFD;
                color: #ffffff;
            }

            .my_table_configurationsOverview tbody tr:default {
                background-color: white;
                color: black;
            }


    .my_table_borderBottom {
        border-bottom: 1px solid black;
    }

    .my_table_cell {
        border: 1px solid black;
        padding: 0.5rem;
    }

    .my_table_firstColumn {
        width: 50%;
        text-align: right;
    }

    .my_table_column {
        width: 25%;
        text-align: center;
    }

    .my_table_secondColumn {
        width: 25%;
        text-align: right;
    }

    .my_table_thirdColumn {
        width: 25%;
        text-align: left;
    }

    .my_table_row {
        padding: 0.5rem;
    }

    .ZAbrand {
        max-width: 225px;
        max-height: 40px;
    }

    .ZAfont_family {
        font-family: SF Pro Text,SF Pro Icons,AOS Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    }

    .ZAfont_size {
        font-size: 16px;
    }

    .c-footer.c-footer-fixed {
        z-index: 1000;
    }

    .my_div_minWidth_100 {
        min-width: 100px;
    }

    .my_div_maginleft_5 {
        margin-left: 5px;
    }

    .my_div_maginleft_10 {
        margin-left: 10px;
    }

    .my_summaryInfo {
        border-left: 2px solid rgba(65,105,225,0.1);
        padding-left: 10px;
        /*background-color: rgba(65,105,225,0.1);*/
    }

    @media (min-width: 768px) {
        .c-main > .container-fluid, .c-main > .container-sm, .c-main > .container-md, .c-main > .container-lg, .c-main > .container-xl {
            padding-right: 0px;
            padding-left: 0px;
        }
    }

    @media screen and (max-width: 1500px) {
        div.my_div_width1500 {
            grid-template-columns: auto 220px !important;
        }
    }

    @media screen and (max-width: 1500px) {
        div.width1500 {
            display: none;
        }
    }

    @media screen and (max-width: 1400px) {
        div.width1400_lg {
            display: unset !important;
        }
    }

    @media screen and (max-width: 1400px) {
        div.width1400 {
            display: none;
        }
    }

    @media screen and (max-width: 1000px) {
        div.width1000 {
            display: none;
        }
    }

    @media screen and (max-width: 800px) {
        div.width800 {
            display: none;
        }
    }
</style>
