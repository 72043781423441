<template>
    <div>
        <div style="display:inline-block;" v-bind:class="{container_xs: loaderSize=='xs', container_lg: loaderSize=='lg'}">
            <div style="content: ''; display: block; border-radius: 50%; border-color: #000000 transparent #000000 transparent; animation: loaderAnimation 1.2s linear infinite" v-bind:class="{loader_xs: loaderSize=='xs', loader_lg: loaderSize=='lg'}"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoaderIcon',
        props: {
            loaderSize: String,
        },
    }
</script>

<style>

    .container_xs {
        width: 18px;
        height: 18px;
    }

    .loader_xs {
        width: 18px;
        height: 18px;
        border: 2px solid #000000;
    }

    .container_lg {
        width: 75px;
        height: 75px;
    }

    .loader_lg {
        width: 75px;
        height: 75px;
        border: 6px solid #000000;
    }


    @keyframes loaderAnimation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>
