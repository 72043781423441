<template>
    <div>
        <div style="height:72px; background-color:#ffffff; border-bottom: 1px solid #ced2d8; display:flex">
            <div style="margin:16px 30px 16px 4%; width: 225px">
                <img style="cursor: pointer" @click="to_startPage" class="ZAbrand" src="../../public/img/ZAbrands/ZAoriginal.svg" />
            </div>
            <div style="margin:16px 4% 16px auto; display:flex; right:0; column-gap:20px" class="align-items-center">
                <div class="header_container" id="language-container">
                    <a href="#"
                       @click="toggleLanguageSelectionDropdown"
                       class="d-flex align-items-center text-decoration-none">
                        <span class="ZAfont_family ZAfont_size mr-2" style="font-weight:600">{{selected_language}}</span>
                        <span>
                            <i class="fal fa-globe"
                               style="font-size:35px"></i>
                        </span>
                    </a>
                    <div v-if="my_languageSelection_isActive" class="header-dropdown">
                        <ul class="ZAfont_family mt-2">
                            <li v-for="lang in languages" @click="set_language(lang.key)">
                                <a href="#"><span>{{lang.description}}</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="header_container" id="profile-container">
                    <a href="#"
                       @click="toggleProfileDropdown"
                       class="d-flex align-items-center text-decoration-none">
                        <div class="ZAfont_family ZAfont_size mr-2" style="font-weight:600; vertical-align:middle">{{$t('3268')}}, {{userProfile.given_name}} {{userProfile.family_name}}</div>
                        <div>
                            <i class="fal fa-user" style="font-size:35px"></i>
                        </div>
                    </a>
                    <div v-if="showProfileDropdown" class="header-dropdown">
                        <ul class="ZAfont_family mt-2">
                            <li>
                                <a @click="show_userPreferences" href="#">
                                    <span><i class="fal fa-cog mr-2"></i><span>{{$t('3241')}}</span></span>
                                </a>
                            </li>
                            <li>
                                <a @click="logout" href="#">
                                    <span><i class="fal fa-sign-out mr-2"></i><span>{{$t('3242')}}</span></span>
                                </a>
                            </li>
                            <li>
                                <span style="font-size: 12px; margin-left: 15px; margin-right: 5px">{{userProfile.id}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '../router';

    export default {
        name: 'TheHeader',
        data() {
            return {
                //selected_language: 'DE',
                selected_language: null,
                my_languageSelection_isActive: false,
                showProfileDropdown: false,
                languages: [
                    { key: 'de', description: 'Deutsch' },
                    { key: 'en', description: 'English' }
                ]
            }
        },
        created() {
            try {
                const lan = navigator.language || navigator.userLanguage
                if (lan != null && lan.indexOf("en") > -1) {
                    this.set_language('en');
                }
                else if (lan != null && lan.indexOf("de") > -1) {
                    this.set_language('de');
                }
                else {
                    this.set_language('en');
                }
            }
            catch {
                this.set_language('de');
            }
        },
        computed: {
            userProfile: {
                get() {
                    return this.$store.state.account.user.profile
                }
            },
        },
        methods: {
            to_startPage() {
                router.push({ path: '/' })
            },

            hideLanguageSelectionDropdown() {
                this.my_languageSelection_isActive = false;
            },
            hideProfileDropdown() {
                this.showProfileDropdown = false;
            },
            toggleLanguageSelectionDropdown() {
                this.my_languageSelection_isActive = !this.my_languageSelection_isActive;
            },
            toggleProfileDropdown() {
                this.showProfileDropdown = !this.showProfileDropdown;
            },
            show_userPreferences() {
                this.hideProfileDropdown();
                this.$store.dispatch('user/change_userPreference_Modal_Shown_storeAction', true)
            },
            set_language(value) {
                this.hideLanguageSelectionDropdown();
                this.$i18n.i18next.changeLanguage(value);
                this.selected_language = value.toUpperCase();
                this.$store.dispatch('configurations/change_selectedLanguage_storeAction', value);
            },
            logout() {
                this.showProfileDropdown = false;
                this.$store.dispatch('account/logout');
            },
            handleDropdownOutsideClick(e) {
                let profileContainer = document.getElementById('profile-container');
                if (profileContainer && !profileContainer.contains(e.target) && this.showProfileDropdown) {
                    this.hideProfileDropdown();
                }

                let languageContainer = document.getElementById('language-container');
                if (languageContainer && !languageContainer.contains(e.target) && this.my_languageSelection_isActive) {
                    this.hideLanguageSelectionDropdown();
                }
            }
        },
        mounted() {
            document.addEventListener('click', this.handleDropdownOutsideClick);
        },
        destroyed() {
            document.removeEventListener('click', this.handleDropdownOutsideClick);
        }
    }
</script>

<style>
    .header-dropdown ul {
        background-color: #ffffff;
        border: 1px solid #ced2d8;
        list-style: none;
        margin: 0px;
        padding: 0px;
        position: fixed;
        z-index: 999;
        font-size: 1rem;
    }

    .header-dropdown li a {
        color: inherit;
        text-decoration: none;
        display: list-item;
        padding: 12px;
    }

        .header-dropdown li a span {
            padding: 0 4px 6px 4px;
            border-bottom: 2px solid transparent;
        }

        .header-dropdown li a:is(:hover,:focus) span {
            border-color: black;
        }

    .header_container a {
        color: inherit;
    }
</style>