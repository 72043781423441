<template>
    <div v-if="(Object.keys(statusHandling).length > 0) && (Object.keys(statusHandling.status).length > 0)" class="my_modal" align="center" style="z-index:2000 !important">
        <div class="my_modal-dialog size-sm" style="align-items:center">
            <div class="my_modal-content">
                <div class="my_modal-header" style="border-bottom:none">
                    <div v-if="(((statusHandling.status.statusInContextOf!='check_configuration_fromDatabase')&&(statusHandling.status.statusInContextOf!='check_configuration_fromFile')&&(statusHandling.status.statusInContextOf!='check_configurationXML_fromFile'))||(((statusHandling.status.statusInContextOf=='check_configuration_fromDatabase')||(statusHandling.status.statusInContextOf=='check_configuration_fromFile'))&&((statusHandling.status.statusCode.startsWith('76'))||(statusHandling.status.statusCode.length==3))))">
                        <div class="my_color_00338e" style="display:inline-flex">
                            <div><i class="fal fa-info-square fa-2x"></i></div>
                            <div><div class="my_fontSize_1Point2 ZAfont_family" style="margin-left:5px">{{$t('1367')}}</div></div>
                        </div>
                    </div>
                </div>
                <div class="my_modal-body">
                    <div v-if="(((statusHandling.status.statusInContextOf!='check_configuration_fromDatabase')&&(statusHandling.status.statusInContextOf!='check_configuration_fromFile')&&(statusHandling.status.statusInContextOf!='check_configurationXML_fromFile'))||(((statusHandling.status.statusInContextOf=='check_configuration_fromDatabase')||(statusHandling.status.statusInContextOf=='check_configuration_fromFile'))&&((statusHandling.status.statusCode.startsWith('76'))||(statusHandling.status.statusCode.length==3))))">
                        <div v-for="statusInformation in statusHandling.status.statusInformations">
                            <div class="ZAfont_family ZAfont_size">{{$t(statusInformation.textKey, statusInformation.textValues)}}</div>
                        </div>
                    </div>
                    <div v-if="(((statusHandling.status.statusInContextOf=='check_configuration_fromDatabase')||(statusHandling.status.statusInContextOf=='check_configuration_fromFile') ||(statusHandling.status.statusInContextOf=='check_configurationXML_fromFile'))&&(statusHandling.status.statusCode.startsWith('76')==false)&&(statusHandling.status.statusCode.length!=3))" style="display:flex;">
                        <div style="flex: 1">
                            <div align="center"><span class="fal fa-info-square fa-7x my_color_00338e"></span></div>
                        </div>
                        <div style="flex: 2">
                            <div class="my_fontSize_1Point2 my_font-weight_600 my_color_00338e ZAfont_family">{{$t('3273')}}</div>
                            <div v-for="statusInformation in statusHandling.status.statusInformations" align="left">
                                <div class="ZAfont_family ZAfont_size">{{$t(statusInformation.textKey, statusInformation.textValues)}}</div>
                            </div>
                            <div v-if="((statusHandling.status.statusInContextOf=='check_configuration_fromDatabase')&&(statusHandling.status.statusCode!='8000')&&(statusHandling.status.valueDivergences!=null))">
                                <a class="ZAfont_family ZAfont_size" @click="change_valueDivergenceModal_Shown(true)" href='#'>{{$t('3343')}}</a>
                            </div>
                            <div v-if="statusHandling.status.statusInContextOf=='check_configuration_fromFile'">
                                <a class="ZAfont_family ZAfont_size" href="#" @click="open_file">{{$t('3344')}}</a>
                            </div>
                            <div v-if="statusHandling.status.statusInContextOf=='check_configurationXML_fromFile'">
                                <a class="ZAfont_family ZAfont_size" href="#" @click="open_xmlfile">{{$t('3344')}}</a>
                            </div>
                            <div v-if="((statusHandling.status.statusInContextOf=='check_configuration_fromDatabase')&&(this.statusHandling.data != null)&&(this.statusHandling.data.fileContent != null))">
                                <a class="ZAfont_family ZAfont_size" href="#" @click="open_file">{{$t('3344')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="my_modal-footer" style="border-top:none; flex-direction:column">
                    <div v-for="statusModalClosingOption in statusHandling.status.statusModalClosingOptions" style="margin-top:10px; width:100%">
                        <button @click="hide_statusModal(statusModalClosingOption.closingOption)" class="btn my_btn_standard" style="width:100%">{{$t(statusModalClosingOption.displayedDesignation.textKey)}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StatusModal',
        props: {
            statusHandling: Object,
        },

        methods: {
            hide_statusModal(closingOption) {
                this.$root.$emit('hide_statusModal', closingOption)
            },

            open_file() {
                const winSize = 'width=660,height=620,scrollbars=yes';
                var newWindow = window.open("", "_blank", winSize);
                newWindow.document.write(this.statusHandling.data.fileContent);
                newWindow.document.title = "ZAlift";
            },
            open_xmlfile() {
                let blob = new Blob([this.statusHandling.data.fileContent], { type: 'text/xml' });
                let url = URL.createObjectURL(blob);
                window.open(url);
                URL.revokeObjectURL(url);
            },

            change_valueDivergenceModal_Shown(value) {
                this.$store.dispatch('statusHandling/change_valueDivergenceModal_Shown_storeAction', value);
            },
        },
    }

</script>