<template>
  <CFooter style="background-color:#00338e">
      <div>
          <span style="color:#ffffff">&copy; {{date}} ZIEHL-ABEGG SE</span>
      </div>
    <div class="ml-auto">
    </div>
  </CFooter>
</template>

<script>
    export default {
        name: 'TheFooter',
        data() {
            return {
                date: new Date().getFullYear(),
            }
        },
    }
        
</script>
