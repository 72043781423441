<template>
    <div class="my_modal">
        <div class="my_modal-dialog">
            <div class="my_modal-content">
                <div class="my_modal-header" style="border-bottom:none">
                    <div class="my_fontSize_1Point5 my_fontWeight_600 my_color_00338e ZAfont_family">{{$t('3241')}}</div>
                    <div>
                        <i @click="change_userPreference_Modal_Shown(false)" class="fal fa-times fa-2x"></i>
                    </div>
                </div>
                <div class="my_modal-body" style="height: calc(100vh - 100px)">
                    <div style=" display: grid; grid-template-columns: 325px auto; grid-column-gap: 50px;">
                        <div>
                            <div class="my_navigationBar" style="background-color:#f4f4f4">
                                <ul>
                                    <li @click="selected_userPreference_Option='driveTechnologies'" v-bind:class="{active: selected_userPreference_Option=='driveTechnologies'}"><span class="ZAfont_family ZAfont_size">{{$t('3024')}}</span></li>
                                    <li @click="selected_userPreference_Option='controlTechnologies'" v-bind:class="{active: selected_userPreference_Option=='controlTechnologies'}"><span class="ZAfont_family ZAfont_size">{{$t('3025')}}</span></li>
                                    <li @click="selected_userPreference_Option='general'" v-bind:class="{active: selected_userPreference_Option=='general'}" v-if="za_employee"><span class="ZAfont_family ZAfont_size">{{$t('3358')}}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div style="padding:0px 20px; height:100%">
                                <div v-if="loader_forLoadingResults_at_userPreferences_Modal_Shown==true">
                                    <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                                </div>
                                <div v-else>
                                    <div v-if="selected_userPreference_Option=='driveTechnologies'">
                                        <div v-if="userPreference_elevatorMotor_Machine_Series_Selection!=null">
                                            <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3028')}}</div>
                                            <div>
                                                <div class="ZAfont_family ZAfont_size">{{$t('3227')}}</div>
                                                <ul class="my_userPreference_series">
                                                    <li v-for="itemOf_userPreference_elevatorMotor_Machine_Series_Selection in userPreference_elevatorMotor_Machine_Series_Selection" @click="userPreference_elevatorMotor_Machine_Series=itemOf_userPreference_elevatorMotor_Machine_Series_Selection" v-bind:class="{active: userPreference_elevatorMotor_Machine_Series.includes(itemOf_userPreference_elevatorMotor_Machine_Series_Selection.text)}" class="ZAfont_family ZAfont_size">
                                                        <span v-if="itemOf_userPreference_elevatorMotor_Machine_Series_Selection.useTextKey==false">{{itemOf_userPreference_elevatorMotor_Machine_Series_Selection.text}}</span>
                                                        <span v-if="itemOf_userPreference_elevatorMotor_Machine_Series_Selection.useTextKey==true">{{$t(itemOf_userPreference_elevatorMotor_Machine_Series_Selection.textKey)}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div style="margin-top:20px">
                                            <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3067')}}</div>
                                            <div style="display:flex;">
                                                <div style="margin-right:20px">
                                                    <div class="ZAfont_family ZAfont_size">{{$t('3228')}}</div>
                                                </div>
                                                <div style="padding-left: 5px">
                                                    <input type="checkbox" v-model="userPreference_use_PT100_for_elevatorMotor_TemperatureMonitoring" />
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin-top:20px">
                                            <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('298')}}</div>
                                            <div style="display:flex;">
                                                <div style="margin-right:20px">
                                                    <div class="ZAfont_family ZAfont_size">{{$t('3229')}}</div>
                                                </div>
                                                <div style="padding-left: 5px">
                                                    <input type="checkbox" v-model="userPreference_useOf_elevatorMotor_ForcedVentilation" />
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="((userPreference_elevatorMotor_Brake_Selection!=null)&&(userPreference_elevatorMotor_Brake_NominalVoltage_ValueList!=null)&&(userPreference_elevatorMotor_Brake_MechanicalRelease_Selection!=null)&&(userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection!=null))" style="margin-top:20px">
                                            <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3260')}}</div>
                                            <div>
                                                <div class="ZAfont_family ZAfont_size">{{$t('3073')}}</div>
                                                <div class="my_input-group">
                                                    <select @focus="check_userPreference_elevatorMotor_Brake_NominalVoltage_ValueList" v-model="userPreference_elevatorMotor_Brake_NominalVoltage" class="my_select" style="width:100%">
                                                        <option value="0">{{$t('3174')}}</option>
                                                        <option v-for="userPreference_elevatorMotor_Brake_NominalVoltage in userPreference_elevatorMotor_Brake_NominalVoltage_ValueList" v-bind:disabled="userPreference_elevatorMotor_Brake_NominalVoltage.disabled">{{userPreference_elevatorMotor_Brake_NominalVoltage.nominalVoltage}}</option>
                                                    </select>
                                                    <div class="my_input-group_unit">V</div>
                                                </div>
                                            </div>
                                            <div style="margin-top:12px">
                                                <div class="ZAfont_family ZAfont_size">{{$t('3045')}}</div>
                                                <select @focus="check_userPreference_elevatorMotor_Brake_MechanicalRelease_Selection" v-model="userPreference_elevatorMotor_Brake_MechanicalRelease" class="my_select" style="width:100%">
                                                    <option value="null">{{$t('3174')}}</option>
                                                    <option v-for="userPreference_elevatorMotor_Brake_MechanicalRelease in userPreference_elevatorMotor_Brake_MechanicalRelease_Selection" v-bind:value="userPreference_elevatorMotor_Brake_MechanicalRelease.mechanicalRelease.text" v-bind:disabled="userPreference_elevatorMotor_Brake_MechanicalRelease.disabled">{{$t(userPreference_elevatorMotor_Brake_MechanicalRelease.mechanicalRelease.textKey)}}</option>
                                                </select>
                                            </div>
                                            <div style="margin-top:12px">
                                                <div class="ZAfont_family ZAfont_size">{{$t('208')}}</div>
                                                <select @focus="check_userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection" v-model="userPreference_elevatorMotor_Brake_ReleaseMonitoring" class="my_select" style="width:100%">
                                                    <option value="null">{{$t('3174')}}</option>
                                                    <option v-for="userPreference_elevatorMotor_Brake_ReleaseMonitoring in userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection" v-bind:value="userPreference_elevatorMotor_Brake_ReleaseMonitoring.releaseMonitoring.text" v-bind:disabled="userPreference_elevatorMotor_Brake_ReleaseMonitoring.disabled">{{$t(userPreference_elevatorMotor_Brake_ReleaseMonitoring.releaseMonitoring.textKey)}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="userPreference_elevatorMotor_Encoder_Selection!=null" style="margin-top:20px">
                                            <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3075')}}</div>
                                            <div>
                                                <div class="ZAfont_family ZAfont_size">{{$t('407')}}</div>
                                                <select v-model="userPreference_elevatorMotor_Encoder" class="my_select" style="width:100%">
                                                    <option value="null">{{$t('3174')}}</option>
                                                    <option v-for="userPreference_elevatorMotor_Encoder in userPreference_elevatorMotor_Encoder_Selection">{{userPreference_elevatorMotor_Encoder}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="selected_userPreference_Option=='controlTechnologies'">
                                        <div v-if="userPreference_inverter_Series_Selection!=null">
                                            <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3197')}}</div>
                                            <div>
                                                <div class="ZAfont_family ZAfont_size">{{$t('3227')}}</div>
                                                <ul class="my_userPreference_series">
                                                    <li v-for="itemOf_userPreference_inverter_Series_Selection in userPreference_inverter_Series_Selection" @click="userPreference_inverter_Series=itemOf_userPreference_inverter_Series_Selection" v-bind:class="{active: userPreference_inverter_Series.includes(itemOf_userPreference_inverter_Series_Selection.text)}" class="ZAfont_family ZAfont_size">
                                                        <span v-if="itemOf_userPreference_inverter_Series_Selection.useTextKey==false">{{itemOf_userPreference_inverter_Series_Selection.text}}</span>
                                                        <span v-if="itemOf_userPreference_inverter_Series_Selection.useTextKey==true">{{$t(itemOf_userPreference_inverter_Series_Selection.textKey)}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div style="margin-top:20px">
                                            <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3096')}}</div>
                                            <div style="display:flex">
                                                <div style="margin-right:20px">
                                                    <div class="ZAfont_family ZAfont_size">{{$t('3230')}}</div>
                                                </div>
                                                <div style="padding-left: 5px">
                                                    <input type="checkbox" v-model="userPreference_unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking" />
                                                </div>
                                            </div>
                                            <div v-if="userPreference_unintendedCarMovementCheck_DoorZone_PermissibleValueRange==true" style="margin-top:12px">
                                                <div class="ZAfont_family ZAfont_size">{{$t('3136')}}</div>
                                                <div class="my_input-group">
                                                    <input v-model="userPreference_unintendedCarMovementCheck_DoorZone" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="my_input" v-bind:class="{my_input_border_at_invalidInput: ((userPreference_unintendedCarMovementCheck_DoorZone_ValidationResult.invalid==true)&&(userPreference_unintendedCarMovementCheck_DoorZone_ValidationResult.invalidityReason!=this.$invalidityReasons.notSet))}" style="width:100%" />
                                                    <div class="my_input-group_unit ZAfont_family">mm</div>
                                                </div>
                                                <InvalidityReasons v-if="((userPreference_unintendedCarMovementCheck_DoorZone_ValidationResult.invalid==true)&&((userPreference_unintendedCarMovementCheck_DoorZone_ValidationResult.hasOwnProperty('invalidityReason')==false)||((userPreference_unintendedCarMovementCheck_DoorZone_ValidationResult.hasOwnProperty('invalidityReason'))&&(userPreference_unintendedCarMovementCheck_DoorZone_ValidationResult.invalidityReason != this.$invalidityReasons.notSet))))" v-bind:validationResult="userPreference_unintendedCarMovementCheck_DoorZone_ValidationResult"></InvalidityReasons>
                                            </div>
                                            <div v-if="userPreference_unintendedCarMovementCheck_DeadTime_PermissibleValueRange==true" style="margin-top:12px">
                                                <div class="ZAfont_family ZAfont_size">{{$t('175')}}</div>
                                                <div class="my_input-group">
                                                    <input v-model="userPreference_unintendedCarMovementCheck_DeadTime" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="my_input" v-bind:class="{my_input_border_at_invalidInput: ((userPreference_unintendedCarMovementCheck_DeadTime_ValidationResult.invalid==true)&&(userPreference_unintendedCarMovementCheck_DeadTime_ValidationResult.invalidityReason!=this.$invalidityReasons.notSet))}" style="width:100%" />
                                                    <div class="my_input-group_unit ZAfont_family">ms</div>
                                                </div>
                                                <InvalidityReasons v-if="((userPreference_unintendedCarMovementCheck_DeadTime_ValidationResult.invalid==true)&&((userPreference_unintendedCarMovementCheck_DeadTime_ValidationResult.hasOwnProperty('invalidityReason')==false)||((userPreference_unintendedCarMovementCheck_DeadTime_ValidationResult.hasOwnProperty('invalidityReason'))&&(userPreference_unintendedCarMovementCheck_DeadTime_ValidationResult.invalidityReason != this.$invalidityReasons.notSet))))" v-bind:validationResult="userPreference_unintendedCarMovementCheck_DeadTime_ValidationResult"></InvalidityReasons>
                                            </div>
                                            <div v-if="userPreference_unintendedCarMovementCheck_SpeedLimit_ValueList!=null" style="margin-top:12px">
                                                <div class="ZAfont_family ZAfont_size">{{$t('3138')}}</div>
                                                <div class="my_input-group">
                                                    <select v-model="userPreference_unintendedCarMovementCheck_SpeedLimit" class="my_select" style="width:100%">
                                                        <option value="0">{{$t('3174')}}</option>
                                                        <option v-for="userPreference_unintendedCarMovementCheck_SpeedLimit in userPreference_unintendedCarMovementCheck_SpeedLimit_ValueList">{{ValueDecimalSeparator(userPreference_unintendedCarMovementCheck_SpeedLimit)}}</option>
                                                    </select>
                                                    <div class="my_input-group_unit">m/s</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="userPreference_productTypeForConversionOfRegenerativeEnergy_Selection!=null" style="margin-top:20px">
                                            <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3102')}}</div>
                                            <div>
                                                <select v-model="userPreference_productTypeForConversionOfRegenerativeEnergy" class="my_select" style="width:100%">
                                                    <option value="null">{{$t('3174')}}</option>
                                                    <option v-for="userPreference_productTypeForConversionOfRegenerativeEnergy in userPreference_productTypeForConversionOfRegenerativeEnergy_Selection" v-bind:value="userPreference_productTypeForConversionOfRegenerativeEnergy.text">{{$t(userPreference_productTypeForConversionOfRegenerativeEnergy.textKey)}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="((userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_PermissibleValueRange==true)||(userPreference_energyEfficiencyRating_ElectricityCosts_PermissibleValueRange==true))" style="margin-top:20px">
                                            <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3118')}}</div>
                                            <div v-if="userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_PermissibleValueRange==true">
                                                <div class="ZAfont_family ZAfont_size">{{$t('3232')}}</div>
                                                <div class="my_input-group">
                                                    <input v-model="userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="my_input" v-bind:class="{my_input_border_at_invalidInput: ((userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.invalid==true)&&(userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.invalidityReason!=this.$invalidityReasons.notSet))}" style="width:100%" />
                                                    <div class="my_input-group_unit ZAfont_family">W</div>
                                                </div>
                                                <InvalidityReasons v-if="((userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.invalid==true)&&((userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.hasOwnProperty('invalidityReason')==false)||((userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.hasOwnProperty('invalidityReason'))&&(userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult.invalidityReason != this.$invalidityReasons.notSet))))" v-bind:validationResult="userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult"></InvalidityReasons>
                                            </div>
                                            <div v-if="userPreference_energyEfficiencyRating_ElectricityCosts_PermissibleValueRange==true" style="margin-top:12px">
                                                <div class="ZAfont_family ZAfont_size">{{$t('309')}}</div>
                                                <div class="my_input-group">
                                                    <input v-model="userPreference_energyEfficiencyRating_ElectricityCosts" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="my_input" v-bind:class="{my_input_border_at_invalidInput: ((userPreference_energyEfficiencyRating_ElectricityCosts_ValidationResult.invalid==true)&&(userPreference_energyEfficiencyRating_ElectricityCosts_ValidationResult.invalidityReason!=this.$invalidityReasons.notSet))}" style="width:100%" />
                                                    <div class="my_input-group_unit ZAfont_family">{{$t('3110', {currency: 'Cent'})}}</div>
                                                </div>
                                                <InvalidityReasons v-if="((userPreference_energyEfficiencyRating_ElectricityCosts_ValidationResult.invalid==true)&&((userPreference_energyEfficiencyRating_ElectricityCosts_ValidationResult.hasOwnProperty('invalidityReason')==false)||((userPreference_energyEfficiencyRating_ElectricityCosts_ValidationResult.hasOwnProperty('invalidityReason'))&&(userPreference_energyEfficiencyRating_ElectricityCosts_ValidationResult.invalidityReason != this.$invalidityReasons.notSet))))" v-bind:validationResult="userPreference_energyEfficiencyRating_ElectricityCosts_ValidationResult"></InvalidityReasons>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="selected_userPreference_Option=='general'">
                                        <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">ERP Username</div>
                                        <div>
                                            <input type="text" v-model="userPreference_general_erp_username" />
                                        </div>
                                        <div style="margin-top:20px">
                                            <div class="my_fontSize_1Point2 my_color_00338e ZAfont_family">{{$t('3418')}}</div>
                                            <div style="display:flex;">
                                                <div style="margin-right:20px">
                                                    <div class="ZAfont_family ZAfont_size">{{$t('3419')}}</div>
                                                </div>
                                                <div style="padding-left: 5px">
                                                    <input type="checkbox" v-model="userPreference_general_lowestUserLevel" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import InvalidityReasons from '../views/dataValidation/InvalidityReasons';
    import LoaderIcon from '../views/loader/LoaderIcon';
    import axios from 'axios';
    export default {
        name: 'Settings',

        components: {
            InvalidityReasons,
            LoaderIcon,
        },

        data() {
            return {
                selected_userPreference_Option: 'driveTechnologies',
                za_employee: false,
                erp_username: '',
            }
        },
        //watch: {
        //},
        created() {
            axios({
                method: 'post',
                url: '/api/users/return_user_zaemployment_status'
            })
                .then((response) => {
                    this.za_employee = response.data;
                });

        },
        computed: {
            loader_forLoadingResults_at_userPreferences_Modal_Shown: {
                get() {
                    return this.$store.state.user.loader_forLoadingResults_at_userPreferences_Modal_Shown;
                }
            },

            // driveTechnologies
            userPreference_elevatorMotor_Machine_Series_Selection: {
                get() {
                    if (this.$store.state.user.userPreferences_ValueListsAndSelections.hasOwnProperty('userPreference_ElevatorMotor_Machine_Series_Selection')) {
                        return this.$store.state.user.userPreferences_ValueListsAndSelections.userPreference_ElevatorMotor_Machine_Series_Selection;
                    }
                    else {
                        return null;
                    }
                }
            },
            userPreference_elevatorMotor_Brake_Selection: {
                get() {
                    if (this.$store.state.user.userPreferences_ValueListsAndSelections.hasOwnProperty('userPreference_ElevatorMotor_Brake_Selection')) {
                        return this.$store.state.user.userPreferences_ValueListsAndSelections.userPreference_ElevatorMotor_Brake_Selection;
                    }
                    else {
                        return null;
                    }
                }
            },
            userPreference_elevatorMotor_Brake_NominalVoltage_ValueList: {
                get() {
                    if (this.$store.state.user.userPreferences_ValueListsAndSelections.hasOwnProperty('userPreference_ElevatorMotor_Brake_NominalVoltage_ValueList')) {
                        return this.$store.state.user.userPreferences_ValueListsAndSelections.userPreference_ElevatorMotor_Brake_NominalVoltage_ValueList;
                    }
                    else {
                        return null;
                    }
                }
            },
            userPreference_elevatorMotor_Brake_MechanicalRelease_Selection: {
                get() {
                    if (this.$store.state.user.userPreferences_ValueListsAndSelections.hasOwnProperty('userPreference_ElevatorMotor_Brake_MechanicalRelease_Selection')) {
                        return this.$store.state.user.userPreferences_ValueListsAndSelections.userPreference_ElevatorMotor_Brake_MechanicalRelease_Selection;
                    }
                    else {
                        return null;
                    }
                }
            },
            userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection: {
                get() {
                    if (this.$store.state.user.userPreferences_ValueListsAndSelections.hasOwnProperty('userPreference_ElevatorMotor_Brake_ReleaseMonitoring_Selection')) {
                        return this.$store.state.user.userPreferences_ValueListsAndSelections.userPreference_ElevatorMotor_Brake_ReleaseMonitoring_Selection;
                    }
                    else {
                        return null;
                    }
                }
            },
            userPreference_elevatorMotor_Encoder_Selection: {
                get() {
                    if (this.$store.state.user.userPreferences_ValueListsAndSelections.hasOwnProperty('userPreference_ElevatorMotor_Encoder_Selection')) {
                        return this.$store.state.user.userPreferences_ValueListsAndSelections.userPreference_ElevatorMotor_Encoder_Selection;
                    }
                    else {
                        return null;
                    }
                }
            },

            userPreference_elevatorMotor_Machine_Series: {
                get() {
                    if ((this.$store.state.user.userPreferences.hasOwnProperty('elevatorMotor_Machine_Series') == true) && (Array.isArray(this.$store.state.user.userPreferences.elevatorMotor_Machine_Series) == true)) {
                        return this.$store.state.user.userPreferences.elevatorMotor_Machine_Series;
                    }
                    else {
                        return [];
                    }
                },
                set(value) {
                    this.$store.dispatch('user/change_elevatorMotor_Machine_Series_at_userPreferences_storeAction', value.text);
                }
            },
            userPreference_use_PT100_for_elevatorMotor_TemperatureMonitoring: {
                get() {
                    if (this.$store.state.user.userPreferences.hasOwnProperty('use_PT100_For_ElevatorMotor_TemperatureMonitoring') == true) {
                        return this.$store.state.user.userPreferences.use_PT100_For_ElevatorMotor_TemperatureMonitoring;
                    }
                    else {
                        return false;
                    }
                },
                set(value) {
                    this.$store.dispatch('user/change_use_PT100_for_elevatorMotor_TemperatureMonitoring_at_userPreferences_storeAction', value);
                }
            },
            userPreference_useOf_elevatorMotor_ForcedVentilation: {
                get() {
                    if (this.$store.state.user.userPreferences.hasOwnProperty('useOf_ElevatorMotor_ForcedVentilation') == true) {
                        return this.$store.state.user.userPreferences.useOf_ElevatorMotor_ForcedVentilation;
                    }
                    else {
                        return false;
                    }
                },
                set(value) {
                    this.$store.dispatch('user/change_useOf_elevatorMotor_ForcedVentilation_at_userPreferences_storeAction', value);
                }
            },
            userPreference_elevatorMotor_Brake_NominalVoltage: {
                get() {
                    if (this.$store.state.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_NominalVoltage') == true) {
                        return this.$store.state.user.userPreferences.elevatorMotor_Brake_NominalVoltage;
                    }
                    else {
                        return 0;
                    }
                },
                set(value) {
                    this.$store.dispatch('user/change_elevatorMotor_Brake_NominalVoltage_at_userPreferences_storeAction', parseInt(value));
                },
            },
            userPreference_elevatorMotor_Brake_MechanicalRelease: {
                get() {
                    if (this.$store.state.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_MechanicalRelease') == true) {
                        return this.$store.state.user.userPreferences.elevatorMotor_Brake_MechanicalRelease;
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    if (value == "null") {
                        value = null;
                    }

                    this.$store.dispatch('user/change_elevatorMotor_Brake_MechanicalRelease_at_userPreferences_storeAction', value);
                },
            },
            userPreference_elevatorMotor_Brake_ReleaseMonitoring: {
                get() {
                    if (this.$store.state.user.userPreferences.hasOwnProperty('elevatorMotor_Brake_ReleaseMonitoring') == true) {
                        return this.$store.state.user.userPreferences.elevatorMotor_Brake_ReleaseMonitoring;
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    if (value == "null") {
                        value = null;
                    }

                    this.$store.dispatch('user/change_elevatorMotor_Brake_ReleaseMonitoring_at_userPreferences_storeAction', value);
                },
            },
            userPreference_elevatorMotor_Encoder: {
                get() {
                    if (this.$store.state.user.userPreferences.hasOwnProperty('elevatorMotor_Encoder') == true) {
                        return this.$store.state.user.userPreferences.elevatorMotor_Encoder;
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.$store.dispatch('user/change_elevatorMotor_Encoder_at_userPreferences_storeAction', value);
                }
            },

            // controlTechnology
            userPreference_inverter_Series_Selection: {
                get() {
                    if (this.$store.state.user.userPreferences_ValueListsAndSelections.hasOwnProperty('userPreference_Inverter_Series_Selection')) {
                        return this.$store.state.user.userPreferences_ValueListsAndSelections.userPreference_Inverter_Series_Selection;
                    }
                    else {
                        return null;
                    }
                }
            },
            userPreference_productTypeForConversionOfRegenerativeEnergy_Selection: {
                get() {
                    if (this.$store.state.user.userPreferences_ValueListsAndSelections.hasOwnProperty('userPreference_ProductTypeForConversionOfRegenerativeEnergy_Selection')) {
                        return this.$store.state.user.userPreferences_ValueListsAndSelections.userPreference_ProductTypeForConversionOfRegenerativeEnergy_Selection;
                    }
                    else {
                        return null;
                    }
                }
            },
            userPreference_unintendedCarMovementCheck_DoorZone_PermissibleValueRange: {
                get() {
                    return this.$store.state.user.userPreferences_unintendedCarMovementCheck_PermissibleValueRanges.hasOwnProperty('doorZone')
                }
            },
            userPreference_unintendedCarMovementCheck_DeadTime_PermissibleValueRange: {
                get() {
                    return this.$store.state.user.userPreferences_unintendedCarMovementCheck_PermissibleValueRanges.hasOwnProperty('deadTime')
                }
            },
            userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_PermissibleValueRange: {
                get() {
                    return this.$store.state.user.userPreferences_PermissibleValueRanges.hasOwnProperty('standbyPowerConsumptionOfCarLightAndElevatorControlSystem')
                }
            },
            userPreference_energyEfficiencyRating_ElectricityCosts_PermissibleValueRange: {
                get() {
                    return this.$store.state.user.userPreferences_PermissibleValueRanges.hasOwnProperty('electricityCostsPerKilowattHour')
                }
            },

            userPreference_inverter_Series: {
                get() {
                    if ((this.$store.state.user.userPreferences.hasOwnProperty('inverter_Series') == true) && (Array.isArray(this.$store.state.user.userPreferences.inverter_Series) == true)) {
                        return this.$store.state.user.userPreferences.inverter_Series;
                    }
                    else {
                        return []
                    }
                },
                set(value) {
                    this.$store.dispatch('user/change_inverter_Series_at_userPreferences_storeAction', value.text);
                }
            },
            userPreference_unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking: {
                get() {
                    if (this.$store.state.user.userPreferences.hasOwnProperty('unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking') == true) {
                        return this.$store.state.user.userPreferences.unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking;
                    }
                    else {
                        return false;
                    }
                },
                set(value) {
                    this.$store.dispatch('user/change_unintendedCarMovementCheck_CalculatedWithShortCircuitMotorBraking_at_userPreferences_storeAction', value);
                }
            },

            userPreference_unintendedCarMovementCheck_SpeedLimit_ValueList: {
                get() {
                    if (this.$store.state.user.userPreferences_ValueListsAndSelections.hasOwnProperty('userPreference_unintendedCarMovementCheck_SpeedLimit_ValueList')) {
                        return this.$store.state.user.userPreferences_ValueListsAndSelections.userPreference_unintendedCarMovementCheck_SpeedLimit_ValueList;
                    }
                    else {
                        return null;
                    }
                }
            },
            userPreference_unintendedCarMovementCheck_SpeedLimit: {
                get() {
                    if (this.$store.state.user.userPreferences.hasOwnProperty('unintendedCarMovementCheck_SpeedLimit') == true) {
                        const localDecimal = { value: this.$store.state.user.userPreferences.unintendedCarMovementCheck_SpeedLimit, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                    else {
                        return 0;
                    }
                },
                set(value) {
                    //if (value == "null" || value == 0 || value == 0.0) {
                    //    value = null;
                    //}
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'speedLimit' }
                    this.$store.dispatch('user/set_decimalValue', localDecimal);
                }
            },
            userPreference_unintendedCarMovementCheck_DoorZone: {
                get() {
                    return this.$store.state.user.unintendedCarMovementCheck_DoorZone;
                },
                set(value) {
                    this.$store.dispatch('user/change_unintendedCarMovementCheck_DoorZone_at_userPreferences_storeAction', value);
                }
            },
            userPreference_unintendedCarMovementCheck_DeadTime: {
                get() {
                    return this.$store.state.user.unintendedCarMovementCheck_DeadTime;
                },
                set(value) {
                    this.$store.dispatch('user/change_unintendedCarMovementCheck_DeadTime_at_userPreferences_storeAction', value);
                }
            },
            userPreference_unintendedCarMovementCheck_DoorZone_ValidationResult: {
                get() {
                    return this.$store.state.user.unintendedCarMovementCheck_DoorZone_ValidationResult;
                },
            },
            userPreference_unintendedCarMovementCheck_DeadTime_ValidationResult: {
                get() {
                    return this.$store.state.user.unintendedCarMovementCheck_DeadTime_ValidationResult;
                },
            },

            userPreference_productTypeForConversionOfRegenerativeEnergy: {
                get() {
                    if (this.$store.state.user.userPreferences.hasOwnProperty('productTypeForConversionOfRegenerativeEnergy') == true) {
                        return this.$store.state.user.userPreferences.productTypeForConversionOfRegenerativeEnergy;
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.$store.dispatch('user/change_productTypeForConversionOfRegenerativeEnergy_at_userPreferences_storeAction', value);
                }
            },
            userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem: {
                get() {
                    return this.$store.state.user.energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem;
                },
                set(value) {
                    this.$store.dispatch('user/change_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_at_userPreferences_storeAction', value);
                }
            },
            userPreference_energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult: {
                get() {
                    return this.$store.state.user.energyEfficiencyRating_Standby_PowerConsumptionOfCarLightAndElevatorControlSystem_ValidationResult;
                },
            },
            userPreference_energyEfficiencyRating_ElectricityCosts: {
                get() {
                    return this.$store.state.user.energyEfficiencyRating_ElectricityCosts;
                },
                set(value) {
                    this.$store.dispatch('user/change_energyEfficiencyRating_ElectricityCosts_at_userPreferences_storeAction', value);
                }
            },
            userPreference_energyEfficiencyRating_ElectricityCosts_ValidationResult: {
                get() {
                    return this.$store.state.user.energyEfficiencyRating_ElectricityCosts_ValidationResult;
                },
            },
            userPreference_general_erp_username: {
                get() {
                    if (this.$store.state.user.erp_username != '') {
                        return this.$store.state.user.erp_username;
                    }
                    else {
                        axios({
                            method: 'post',
                            url: '/api/users/return_erp_username'
                        })
                            .then((response) => {
                                if (response.data != null) {

                                    this.$store.dispatch('user/change_erpUsername_at_userPreferences_storeAction', response.data);
                                    return response.data;
                                }
                            });
                    }
                },
                set(value) {
                    this.$store.dispatch('user/change_erpUsername_at_userPreferences_storeAction', value);
                }
            },
            userPreference_general_lowestUserLevel: {
                 get() {
                    if (this.$store.state.user.userPreferences.hasOwnProperty('lowestUserLevel') == true) {
                        return this.$store.state.user.userPreferences.lowestUserLevel;
                    }
                    else {
                        return false;
                    }
                },
                set(value) {
                    this.$store.dispatch('user/change_lowestUserLevel_at_userPreferences_storeAction', value);
                }
            },
        },

        methods: {
            ValueDecimalSeparator(value) {
                if (value != null) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                }
                return value;
            },
            change_userPreference_Modal_Shown(value) {
                this.$store.dispatch('user/change_userPreference_Modal_Shown_storeAction', value)
            },

            change_userPreferences_erp_username(e) {
                this.userPreferences_erp_username = e.target.value;
            },

            // driveTechnologies
            check_userPreference_elevatorMotor_Brake_NominalVoltage_ValueList() {
                var filtered_userPreference_elevatorMotor_Brake_Selection = [];
                if ((this.userPreference_elevatorMotor_Brake_MechanicalRelease == null) && (this.userPreference_elevatorMotor_Brake_ReleaseMonitoring == null)) {
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_NominalVoltage_ValueList(this.userPreference_elevatorMotor_Brake_Selection)
                }
                else if ((this.userPreference_elevatorMotor_Brake_MechanicalRelease == null) && (this.userPreference_elevatorMotor_Brake_ReleaseMonitoring != null)) {
                    filtered_userPreference_elevatorMotor_Brake_Selection = this.userPreference_elevatorMotor_Brake_Selection.filter(i => {
                        if (i.releaseMonitoring.text == this.userPreference_elevatorMotor_Brake_ReleaseMonitoring) {
                            return true;
                        }
                        return false;
                    });
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_NominalVoltage_ValueList(filtered_userPreference_elevatorMotor_Brake_Selection)
                }
                else if ((this.userPreference_elevatorMotor_Brake_MechanicalRelease != null) && (this.userPreference_elevatorMotor_Brake_ReleaseMonitoring == null)) {
                    filtered_userPreference_elevatorMotor_Brake_Selection = this.userPreference_elevatorMotor_Brake_Selection.filter(i => {
                        if (i.mechanicalRelease.text == this.userPreference_elevatorMotor_Brake_MechanicalRelease) {
                            return true;
                        }
                        return false;
                    });
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_NominalVoltage_ValueList(filtered_userPreference_elevatorMotor_Brake_Selection)
                }
                else if ((this.userPreference_elevatorMotor_Brake_MechanicalRelease != null) && (this.userPreference_elevatorMotor_Brake_ReleaseMonitoring != null)) {
                    filtered_userPreference_elevatorMotor_Brake_Selection = this.userPreference_elevatorMotor_Brake_Selection.filter(i => {
                        if ((i.mechanicalRelease.text == this.userPreference_elevatorMotor_Brake_MechanicalRelease) && (i.releaseMonitoring.text == this.userPreference_elevatorMotor_Brake_ReleaseMonitoring)) {
                            return true;
                        }
                        return false;
                    });
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_NominalVoltage_ValueList(filtered_userPreference_elevatorMotor_Brake_Selection)
                }
            },
            check_userPreference_elevatorMotor_Brake_MechanicalRelease_Selection() {
                var filtered_userPreference_elevatorMotor_Brake_Selection = [];
                if ((this.userPreference_elevatorMotor_Brake_NominalVoltage == 0) && (this.userPreference_elevatorMotor_Brake_ReleaseMonitoring == null)) {
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_MechanicalRelease_Selection(this.userPreference_elevatorMotor_Brake_Selection)
                }
                else if ((this.userPreference_elevatorMotor_Brake_NominalVoltage == 0) && (this.userPreference_elevatorMotor_Brake_ReleaseMonitoring != null)) {
                    filtered_userPreference_elevatorMotor_Brake_Selection = this.userPreference_elevatorMotor_Brake_Selection.filter(i => {
                        if (i.releaseMonitoring.text == this.userPreference_elevatorMotor_Brake_ReleaseMonitoring) {
                            return true;
                        }
                        return false;
                    });
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_MechanicalRelease_Selection(filtered_userPreference_elevatorMotor_Brake_Selection)
                }
                else if ((this.userPreference_elevatorMotor_Brake_NominalVoltage > 0) && (this.userPreference_elevatorMotor_Brake_ReleaseMonitoring == null)) {
                    filtered_userPreference_elevatorMotor_Brake_Selection = this.userPreference_elevatorMotor_Brake_Selection.filter(i => {
                        if (i.nominalVoltage == this.userPreference_elevatorMotor_Brake_NominalVoltage) {
                            return true;
                        }
                        return false;
                    });
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_MechanicalRelease_Selection(filtered_userPreference_elevatorMotor_Brake_Selection)
                }
                else if ((this.userPreference_elevatorMotor_Brake_NominalVoltage > 0) && (this.userPreference_elevatorMotor_Brake_ReleaseMonitoring != null)) {
                    filtered_userPreference_elevatorMotor_Brake_Selection = this.userPreference_elevatorMotor_Brake_Selection.filter(i => {
                        if ((i.nominalVoltage == this.userPreference_elevatorMotor_Brake_NominalVoltage) && (i.releaseMonitoring.text == this.userPreference_elevatorMotor_Brake_ReleaseMonitoring)) {
                            return true;
                        }
                        return false;
                    });
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_MechanicalRelease_Selection(filtered_userPreference_elevatorMotor_Brake_Selection)
                }
            },
            check_userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection() {
                var filtered_userPreference_elevatorMotor_Brake_Selection = [];
                if ((this.userPreference_elevatorMotor_Brake_NominalVoltage == 0) && (this.userPreference_elevatorMotor_Brake_MechanicalRelease == null)) {
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection(this.userPreference_elevatorMotor_Brake_Selection)
                }
                else if ((this.userPreference_elevatorMotor_Brake_NominalVoltage == 0) && (this.userPreference_elevatorMotor_Brake_MechanicalRelease != null)) {
                    filtered_userPreference_elevatorMotor_Brake_Selection = this.userPreference_elevatorMotor_Brake_Selection.filter(i => {
                        if (i.mechanicalRelease.text == this.userPreference_elevatorMotor_Brake_MechanicalRelease) {
                            return true;
                        }
                        return false;
                    });
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection(filtered_userPreference_elevatorMotor_Brake_Selection)
                }
                else if ((this.userPreference_elevatorMotor_Brake_NominalVoltage > 0) && (this.userPreference_elevatorMotor_Brake_MechanicalRelease == null)) {
                    filtered_userPreference_elevatorMotor_Brake_Selection = this.userPreference_elevatorMotor_Brake_Selection.filter(i => {
                        if (i.nominalVoltage == this.userPreference_elevatorMotor_Brake_NominalVoltage) {
                            return true;
                        }
                        return false;
                    });
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection(filtered_userPreference_elevatorMotor_Brake_Selection)
                }
                else if ((this.userPreference_elevatorMotor_Brake_NominalVoltage > 0) && (this.userPreference_elevatorMotor_Brake_MechanicalRelease != null)) {
                    filtered_userPreference_elevatorMotor_Brake_Selection = this.userPreference_elevatorMotor_Brake_Selection.filter(i => {
                        if ((i.nominalVoltage == this.userPreference_elevatorMotor_Brake_NominalVoltage) && (i.mechanicalRelease.text == this.userPreference_elevatorMotor_Brake_MechanicalRelease)) {
                            return true;
                        }
                        return false;
                    });
                    this.change_Disabled_at_userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection(filtered_userPreference_elevatorMotor_Brake_Selection)
                }
            },

            change_Disabled_at_userPreference_elevatorMotor_Brake_NominalVoltage_ValueList(value) {
                var possible_userPreference_elevatorMotor_Brake_NominalVoltages = [...new Set(value.map(i => i.nominalVoltage))];
                if (possible_userPreference_elevatorMotor_Brake_NominalVoltages.length > 0) {
                    this.userPreference_elevatorMotor_Brake_NominalVoltage_ValueList.forEach(i => {
                        i.disabled = true;
                        possible_userPreference_elevatorMotor_Brake_NominalVoltages.forEach(ii => {
                            if (ii == i.nominalVoltage) {
                                i.disabled = false;
                            }
                        })
                    })
                }
                else if (possible_userPreference_elevatorMotor_Brake_NominalVoltages.length == 0) {
                    this.userPreference_elevatorMotor_Brake_NominalVoltage_ValueList.forEach(i => {
                        i.disabled = false;
                    })
                }
            },
            change_Disabled_at_userPreference_elevatorMotor_Brake_MechanicalRelease_Selection(value) {
                var possible_userPreference_elevatorMotor_Brake_MechanicalReleases = [...new Set(value.map(i => i.mechanicalRelease))];
                if (possible_userPreference_elevatorMotor_Brake_MechanicalReleases.length > 0) {
                    this.userPreference_elevatorMotor_Brake_MechanicalRelease_Selection.forEach(i => {
                        i.disabled = true;
                        possible_userPreference_elevatorMotor_Brake_MechanicalReleases.forEach(ii => {
                            if (ii.text == i.mechanicalRelease.text) {
                                i.disabled = false;
                            }
                        })
                    })
                }
                else if (possible_userPreference_elevatorMotor_Brake_MechanicalReleases.length == 0) {
                    this.userPreference_elevatorMotor_Brake_MechanicalRelease_Selection.forEach(i => {
                        i.disabled = false;
                    })
                }
            },
            change_Disabled_at_userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection(value) {
                var possible_userPreference_elevatorMotor_Brake_ReleaseMonitorings = [...new Set(value.map(i => i.releaseMonitoring))];
                if (possible_userPreference_elevatorMotor_Brake_ReleaseMonitorings.length > 0) {
                    this.userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection.forEach(i => {
                        i.disabled = true;
                        possible_userPreference_elevatorMotor_Brake_ReleaseMonitorings.forEach(ii => {
                            if (ii.text == i.releaseMonitoring.text) {
                                i.disabled = false;
                            }
                        })
                    })
                }
                else if (possible_userPreference_elevatorMotor_Brake_ReleaseMonitorings.length == 0) {
                    this.userPreference_elevatorMotor_Brake_ReleaseMonitoring_Selection.forEach(i => {
                        i.disabled = false;
                    })
                }
            },
        },
    }

</script>


<style>

    .my_userPreference_series {
        list-style: none;
        padding: 0;
        margin: 0;
    }

        .my_userPreference_series li {
            display: inline-flex;
            padding: 9px;
            border: 1px solid #ced2d8;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .my_userPreference_series .active {
            border: 1px solid blue;
        }

    .my_navigationBar ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .my_navigationBar li {
        padding: 12px;
        border-top: 1px solid #ced2d8;
        font-weight: 600;
    }

        .my_navigationBar li:last-child {
            border-bottom: 1px solid #ced2d8;
        }

    .my_navigationBar .active {
        border-left: 4px solid #00338e;
        color: #00338e;
        font-weight: 700;
    }
</style>
