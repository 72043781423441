<template>
    <div class="my_modal" align="center">
        <div class="my_modal-dialog size-sm" style="align-items:center">
            <div class="my_modal-content">
                <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                <div class="my_fontSize_1Point1 ZAfont_family">
                    <div v-if="reasonWhy_loaderModal_Shown=='data_BeingLoaded'">{{$t('3345')}} ...</div>
                    <div v-else-if="reasonWhy_loaderModal_Shown=='configuration_fromDatabase_BeingChecked'">{{$t('3347')}} ...</div>
                    <div v-else-if="reasonWhy_loaderModal_Shown=='configuration_fromFile_BeingChecked'">{{$t('3346')}} ...</div>
                    <div v-else-if="reasonWhy_loaderModal_Shown=='databaseRecords_BeingInsertedOrUpdated'">{{$t('3348')}} ...</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import LoaderIcon from '../loader/LoaderIcon';
    export default {
        name: 'LoaderModal',

        components: {
            LoaderIcon,
        },

        props: {
            reasonWhy_loaderModal_Shown: String,
        },
    }

</script>